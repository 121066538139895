/**
 * 轮播图管理
 */
import { axios } from '@/utils/request'

//轮播图列表
export function bannerPage(parameter) {
  return axios({
    url: '/banner/page',
    method: 'post',
    params: parameter.page,
    data: parameter.queryParam,
  })
}
//轮播图详情
export function bannerDetail(data) {
  return axios({
    url: '/banner/detail',
    method: 'post',
    data,
  })
}

//删除轮播图
export function bannerDelete(data) {
  return axios({
    url: '/banner/delete',
    method: 'post',
    data,
  })
}

// 添加轮播图
export function bannerAdd(data) {
  return axios({
    url: '/banner/add',
    method: 'post',
    data,
  })
}
// 编辑轮播图
export function bannerEdit(data) {
  return axios({
    url: '/banner/edit',
    method: 'post',
    data,
  })
}
