<template>
  <div>
    <a-modal
      title="编辑轮播图"
      width="50%"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-spin :spinning="confirmLoading">
        <a-row :gutter="24">
          <a-col :md="24" :sm="24">
            <a-form :form="form">
              <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select
                  v-decorator="['type', { rules: [{ required: true, message: '请选择类型！' }] }]"
                  allow-clear
                  placeholder="请选择"
                  @change="handleSelectChange"
                >
                  <a-select-option v-for="(item, index) in typeList" :key="index" :value="item.value">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="商品" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="+bannerType === 1">
                <div
                  style="cursor: pointer; width: 100%; height: 100%; position: absolute; z-index: 99; left: 0; top: 0"
                  @click="chooseProduct"
                ></div>
                <a-input
                  disabled
                  placeholder="请选择"
                  v-decorator="['objectName', { rules: [{ required: true, message: '请选择商品' }] }]"
                >
                  <span slot="suffix"> <a-icon type="right" /></span>
                </a-input>
                <a-input
                  style="position: absolute; top: 99999px; visibility: hidden"
                  placeholder="请选择"
                  v-decorator="['objectId', { rules: [{ required: true, message: '请选择商品' }] }]"
                />
              </a-form-item>
              <a-form-item label="商品分类" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="+bannerType === 2">
                <a-tree-select
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  tree-default-expand-all
                  v-decorator="['objectId', { rules: [{ required: true, message: '请选择商品分类!' }] }]"
                >
                  <a-tree-select-node
                    v-for="parentItem in goods_cate_list"
                    :key="parentItem.id"
                    :value="parentItem.id"
                    :title="parentItem.name"
                    :selectable="false"
                  >
                    <a-tree-select-node
                      v-for="item in parentItem.childrenList"
                      :key="item.id"
                      :selectable="true"
                      :value="item.id"
                      :title="item.name"
                    />
                  </a-tree-select-node>
                </a-tree-select>
              </a-form-item>
              <a-form-item label="内容" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="+bannerType === 3">
                <a-input
                  disabled
                  style="visibility: hidden; position: absolute; top: 99999px"
                  placeholder="请选择"
                  v-decorator="['content', { rules: [{ required: true, message: '请输入内容' }] }]"
                />
                <antd-editor
                  style="width: 100%"
                  :uploadConfig="editorUploadConfig"
                  v-model="editorContent"
                  @onchange="changeEditor"
                  @oninit="getEditor"
                />
              </a-form-item>
              <!-- 排序 -->
              <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                <a-input-number
                  placeholder="请输入"
                  :min="1"
                  :precision="0"
                  :max="10000000"
                  style="width: 40%; min-width: 100px"
                  v-decorator="['sort', { rules: [{ required: true, message: '请输入排序！' }], initialValue: 10 }]"
                />
              </a-form-item>
              <!-- 礼物logo -->
              <a-form-item has-feedback label="轮播图" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <div style="width: 104px; height: 104px">
                  <a-upload
                    style="margin: auto"
                    name="avatar"
                    accept=".png,.jpg,jpeg,.gif"
                    list-type="picture-card"
                    :show-upload-list="false"
                    :customRequest="uploadImg"
                    v-decorator="['image', { rules: [{ required: true, message: '请上传轮播图！' }] }]"
                  >
                    <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
                    <div v-else>
                      <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                    </div>
                  </a-upload>
                </div>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </a-spin>
    </a-modal>

    <choose-product ref="chooseProduct" :areaId="areaId" />
  </div>
</template>

<script>
import { AntdEditor } from '@/components'
import { bannerDetail, bannerEdit } from '@/api/modular/mallLiving/banner'
import { uploadFile } from '@/api/modular/mallLiving/uploadFile'
import { allPageList } from '@/api/modular/mallLiving/commodity/commodityClass'
import chooseProduct from './chooseProduct.vue'
import editForm from '@/views/system/bannerList/editForm.vue'

export default {
  components: { editForm, chooseProduct, AntdEditor },
  props: {
    areaId: {
      type: String,
    },
  },
  data() {
    this.lastFetchId = 0
    return {
      lastFetchId: 0,
      fetching: false,
      typeList: [
        {
          name: '商品',
          value: 1,
        },
        {
          name: '商品分类（二级分类）',
          value: 2,
        },
        {
          name: '富文本',
          value: 3,
        },
      ],
      loading: false,
      imageUrl: '',
      picture: '',
      animationUrl: '',
      allList: [],
      animationList: [],
      searchList: [],
      goods_cate_list: [],
      pictureLoading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      labelCol_1: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol_1: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      bannerType: '1', //1商品 2商品分类 3富文本
      id: '',
      editorContent: '',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
    }
  },
  watch: {
    editorContent(newValue) {
      if (newValue && +this.bannerType === 3) {
        this.form.setFieldsValue({ content: newValue })
      }
    },
  },
  mounted() {
    this.initCateList()
  },
  methods: {
    chooseProduct() {
      this.$refs.chooseProduct.init()
    },
    setProduct(product) {
      console.log(product)
      this.form.setFieldsValue({ objectName: product.objectName })
      this.form.setFieldsValue({ objectId: product.objectId })
    },
    handleSelectChange(value) {
      console.log(value)
      this.bannerType = value
      this.$nextTick(() => {
        this.editorContent = ''
        this.$forceUpdate()
      })
    },
    initCateList() {
      allPageList().then((res) => {
        console.log('res', res)
        if (res.success && Array.isArray(res.data)) {
          this.goods_cate_list = res.data
        }
      })
    },

    // 富文本内容变化
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    // 富文本初始化
    getEditor(editor) {
      this.editor = editor
    },
    // 富文本
    editorUploadImage(files, insert) {
      files.forEach((file) => {
        uploadFile(file.name, file, {
          progress: function (p, checkpoint) {
            const percentage = Math.floor(p * 100)
          },
        }).then((res) => {
          if (res.res.statusCode == 200) {
            const list = res.res.requestUrls[0].split('?')
            if (list.length > 0) {
              insert(list[0])
            }
          }
        })
      })
    },
    beforeUpload(file) {
      this.animationList = [file]
      return true
    },
    // 初始化方法
    edit(record) {
      //下面是正常的初始化方法
      this.visible = true
      this.confirmLoading = true
      this.id = record.id
      bannerDetail({
        id: record.id,
      })
        .then((res) => {
          console.log('res', res)
          if (res.success) {
            const { type, objectId, content, sort, image, goodsName } = res.data
            this.picture = image
            this.bannerType = type
            this.editorContent = content
            this.form.setFieldsValue({ type })
            this.form.setFieldsValue({ sort })
            this.form.setFieldsValue({ image })
            setTimeout(() => {
              if (+type === 1) {
                this.form.setFieldsValue({ objectId })
                this.form.setFieldsValue({ objectName: goodsName })
              }
              if (+type === 2) {
                this.form.setFieldsValue({ objectId })
              }
            }, 100)
          }
        })
        .finally((res) => {
          this.confirmLoading = false
        })
    },
    previewFile(file) {
      console.log('Your upload file:', file)
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          bannerEdit({ ...values, areaId: this.areaId, id: this.id }).then((res) => {
            if (res.success) {
              this.$message.success('编辑轮播图成功！')
              this.$emit('ok', values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.animationList = []
      this.bannerType = ''
      this.editorContent = ''
      this.picture = ''
      this.form.resetFields() //重置表单
    },

    //自定义图片上传实现
    uploadImg(filedata) {
      this.pictureLoading = true
      console.log(filedata)
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            this.picture = list[0]
            this.form.setFieldsValue({ image: list[0] })
            this.pictureLoading = false
          }
        }
      })
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
